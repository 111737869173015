import { ChatEngine } from "react-chat-engine";
import LoginForm from './Components/LoginForm';
import './css/style.css';

const projectID = 'b4c5760c-f024-4bcd-961c-befa564122ce';

function App() {
  if (!localStorage.getItem('username')) return <LoginForm />;
  
  return (
    <ChatEngine
      height="100vh"
      projectID={projectID}
      userName={localStorage.getItem('username')}
      userSecret={localStorage.getItem('password')}
      onNewMessage={() => new Audio('https://chat-engine-assets.s3.amazonaws.com/click.mp3').play()}
    />
  );
}

export default App;
